import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView textCenter column center w="90%" h="95VH">
            <CFImage
              w="80%"
              src={mobileHeroText}
              alt="Little Tokyo Japanese Restaurant Logo Title"
            />
            <CFView mt="25px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="700px"
          w="100%"
          image={`url(${hero}) top/ cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <Header />
          <CFView column center w="100%" maxWidth="1400px" h="660px">
            <CFImage
              h="230px"
              src={heroText}
              alt="Little Tokyo Japanese Restaurant Logo Title"
            />
            <CFView mt="35px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
